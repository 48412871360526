<template>
  <CForm @submit.prevent="save" method="POST">
    <CCard no-header>
      <CCardBody>
        <h3>{{ operationName }} Cancellation Policy</h3>
        <CAlert v-if="message" :color="alertType"> {{ message }}</CAlert>

        <CRow>
          <CCol>
            <CInput label="Key" type="text" v-model="policy.key"></CInput>
          </CCol>
          <CCol col="9"></CCol>
        </CRow>
        <CRow>
          <CCol>
            <label style="width: 95%"
              >Policy Name
              <a @click="toggleNameTranslations = !toggleNameTranslations"
                ><CIcon
                  name="cil-globe-alt"
                  style=""
                  class="text-primary"
                ></CIcon
              ></a>
            </label>
            <br />
            <div
              class="input-group"
              style=""
              v-show="!toggleNameTranslations"
              v-if="policy.nameTranslations[0]"
            >
              <div class="input-group-prepend">
                <span
                  v-text="policy.nameTranslations[0].languageCode"
                  class="input-group-text"
                />
              </div>
              <CInput
                  label=""
                  rows="10"
                  style="width: 100%"
                  v-model="policy.nameTranslations[0].content"
                  :key="'name-' + policy.nameTranslations[0].languageCode"
                />
            </div>
            <div
              v-for="(translation, k) in policy.nameTranslations"
              :key="k"
              v-show="toggleNameTranslations"
            >
              <div class="input-group" style="padding-bottom: 5px">
                <div class="input-group-prepend">
                  <span
                    v-text="translation.languageCode"
                    class="input-group-text"
                  />
                </div>
                <CInput
                    label=""
                    rows="10"
                    style="width: 100%"
                    v-model="policy.nameTranslations[k].content"
                  />
              </div>
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            <label style="width: 95%"
              >Policy Content
              <a @click="toggleContentTranslations = !toggleContentTranslations"
                ><CIcon
                  name="cil-globe-alt"
                  style=""
                  class="text-primary"
                ></CIcon
              ></a>
            </label>
            <br />
            <div
              class="input-group"
              style=""
              v-show="!toggleContentTranslations"
              :key="'content-' + policy.contentTranslations[0].languageCode"
              v-if="policy.contentTranslations[0]"
            >
              <div class="input-group-prepend">
                <span
                  v-text="policy.contentTranslations[0].languageCode"
                  class="input-group-text"
                />
              </div>
              <CTextarea
                  label=""
                  style="width: 100%"
                  rows="10"
                  v-model="policy.contentTranslations[0].content"
                />
            </div>
            <div
              v-for="(translation, k) in policy.contentTranslations"
              :key="k"
              v-show="toggleContentTranslations"
            >
              <div class="input-group" style="padding-bottom: 5px">
                <div class="input-group-prepend">
                  <span
                    v-text="translation.languageCode"
                    class="input-group-text"
                  />
                </div>
                <CTextarea
                    label=""
                    rows="10"
                    style="width: 100%"
                    v-model="policy.contentTranslations[k].content"
                  />
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <span>Percentage return</span>
            <CRow><CCol>&nbsp;</CCol></CRow>
            <CInput v-model="policy.percentageReturn" prepend-content='max="100"' min="0" max="100" type="number" @input="checkPercentage" />
          </CCol>
          <CCol>
              <span>Before departure</span>
              <CRow>
                <CCol>Days</CCol>
                <CCol>Hours</CCol>
                <CCol>Minutes</CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CInput v-model="policy.beforeDepartureDays" min="0" type="number" />
                </CCol>
                <CCol>
                  <CInput v-model="policy.beforeDepartureHours" min="0" type="number"/>
                </CCol>
                <CCol>
                  <CInput v-model="policy.beforeDepartureMinutes" min="0" type="number"/>
                </CCol>
              </CRow>
            </CCol>
        </CRow>
        <CRow>
          <CCol col="8"></CCol>
          <CCol class="d-flex justify-content-end" style="margin-top: 20px">
            <CButton
              color="secondary"
              class="btn-lg"
              @click="goBack"
              style="margin-right: 15px"
              ><CIcon name="cil-list" /> Back to the list</CButton
            >
            <CButton color="primary" type="submit"
              ><CIcon name="cil-save" /> Save</CButton
            >
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CForm>
</template>

<script>
import axios from "axios";
export default {
  name: "AddNewCancellationPolicy",
  data() {
    return {
      operationName: null,
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      toggleContentTranslations: false,
      toggleNameTranslations: false,
      languages: [],
      isEdit: false,
      policy: {
        id: "00000000-0000-0000-0000-000000000000",
        key: null,
        partnerId: "00000000-0000-0000-0000-000000000000",
        contentTranslations: [],
        nameTranslations: [],
      },

      // Alert
      message: null,
      alertType: "danger",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    save() {
      let self = this;

      axios
        .post(`${this.$apiAdress}/v1/CancellationPolicy`, self.policy)
        .then((response) => {
          self.policy.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully created policy.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });
    },
    checkPercentage(value){
      if(value > 100)
        this.policy.percentageReturn = 100;
      if(value <= 0)
        this.policy.percentageReturn = 0;
    },
    getLanguages() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerLanguage`)
        .then(function (response) {
          response.data.forEach(function (language) {
            var translationForName = {
              id: language.id,
              languageCode: language.languageCode,
              content: "",
            };

            var translationForDescription = {
              id: language.id,
              languageCode: language.languageCode,
              content: "",
            };

            if (self.$route.params.id == self.emptyGuid) {
              self.policy.nameTranslations.push(translationForName);
              self.policy.contentTranslations.push(translationForDescription);
            }
          });
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },


    getPolicy(id) {
      let self = this;
      self.getLanguages();
      axios
        .get(`${this.$apiAdress}/v1/CancellationPolicy/${id}`)
        .then(function (response) {
          self.policy = response.data;
          self.check();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    check() {
      let self = this;
      let a = self.policy.contentTranslations.map((a) => a.languageCode);
      let c = self.policy.nameTranslations.map((c) => c.languageCode);
    },
  },

  mounted() {
    let self = this;
    self.getLanguages();
    if (self.$route.params.id != this.emptyGuid) {
      self.operationName = "Edit";
      self.isEdit = true;
      self.getPolicy(self.$route.params.id);
    } else {
      self.operationName = "Create New";
    }
  },
};
</script>
